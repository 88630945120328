<template>
  <div class="mm-input-spinner d-flex justify-content-center">
    <span  type="button" class="buscados-spinner-button pb-less"  @click="modificarCantidad(-1)">
      -    
    </span>
    <input min="1" disabled class="input-cantidad" :value="cantidad">
    <span  type="button" class="buscados-spinner-button"  @click="modificarCantidad(1)">
      +      
    </span>
  </div>
</template>

<script>

export default {
  name: 'InputSpinner',
  data(){
        return{
            cantidad:1,
        }
  },  
  methods: {
      modificarCantidad(modificando) {
        if(modificando == -1 && this.cantidad == 1){
          return;
        }

        if(!(modificando<0 && this.cantidad==0)){
          this.cantidad=this.cantidad+modificando;
        }
      },
  }    
}
</script>

<style scoped lang="scss">
  .mm-input-spinner {
    input {
      border: none;
      padding: 0;
      width: 30px;
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      text-align: center;
      height: 18px;
    }
    button {
      height: 18px;
      padding: 1px 2px;
      line-height: 12px;
      svg {
        stroke-width: 2.5;
      }
    }

    .input-cantidad{
      margin-top: auto;
      margin-bottom:auto;
      background-color: transparent;
    }
  }
</style>
