<template>
  <div class="container px-0">
    <div class="row pt-5 px-2">
      <div v-if="this.resultados.hits.hits.length > 0" class="">
        <p class="mb-0">
          Mostrando {{ this.resultados.hits.hits.length }} resultados de <strong>"{{ this.busqueda }}"</strong>
        </p>
      </div>
      <div v-else class="">
      </div>
    </div>
    <div class="listado-resultados py-5 pt-3 row">
        <card-opensearch 
          :translations="translations" 
          v-for="(item, index) in visibleResults" 
          :key="index"
          :data-id="item._source.doc.obrcod" 
          :obra="item._source" 
          :domain="domain" 
          :avisos="avisos"
          :precio="precios[item._source.doc.obrcod]" 
          :loadingPrice="loadingPrice">
        </card-opensearch>

        <div v-if="loadingMore" class="loading-spinner mb-5">
          <span class="loader opensearch-loader position-relative"></span>
        </div>

        <div v-if="visibleResults.length === 0" class="no-informacion">
          {{translations.noResultados}}
        </div>
    </div>
  </div>
</template>

<script>
import {
  BCol, BRow, BPagination,
} from 'bootstrap-vue'
import FeatherIcon from 'feather-icons-vue';

export default {
  components: {
    BCol,
    BRow,
    BPagination,
    FeatherIcon,
  },
  props: {
      translations: Object,
      resultados: null,
      domain: null,
      avisos: null,
      busqueda: String
  },
  data() {
    return {
      currentPage: 1,
      perPage: 12, // Número de resultados a mostrar por bloque
      visibleResults: [], // Resultados visibles (inicialmente vacíos)
      precios: [],
      error: null,
      loadingPrice: true,
      loadingMore: false,
    };
  },
  mounted() {
    this.loadInitialResults(); // Cargar los primeros resultados
    this.getResultsPrices(); // Cargar precios
    window.addEventListener('scroll', this.onScroll); // Agregar el evento de scroll al montar el componente
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll); // Limpiar el evento de scroll cuando el componente se destruya
  },
  methods: {
    loadInitialResults() {
      this.visibleResults = this.resultados.hits.hits.slice(0, this.perPage);
    },
    onScroll() {
      const bottomOfWindow = window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 10;

      if (bottomOfWindow && !this.loadingMore) {
        this.loadMoreResults();
      }
    },
    loadMoreResults() {
      let neededPages = this.resultados.hits.hits.length / this.perPage;
      let pagination = neededPages % 1 !== 0 ? Math.floor(neededPages) + 1 : neededPages 

      if(this.currentPage == pagination){
        return
      }

      this.loadingMore = true;

      setTimeout(() => {
        const nextPage = this.currentPage + 1;
        const start = this.currentPage * this.perPage;
        const end = nextPage * this.perPage;

        this.visibleResults = this.visibleResults.concat(this.resultados.hits.hits.slice(start, end));
        this.currentPage = nextPage;
        this.loadingMore = false;
      }, 750); // Simulación de un retraso en la carga
    },
    GoToPage(page) {
      if (window.location.search) {
        var query = this.updateQueryStringParameter(window.location.search,'page',page);
        window.location = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
      } else {
        window.location = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + page;
      }
    },
    updateQueryStringParameter(uri, key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf('?') !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
      }
      else {
        return uri + separator + key + "=" + value;
      }
    },
    getResultsPrices() {
      // Obtener la query string de la URL
      const queryString = window.location.search;

      // Parsear los parámetros de la query string
      const urlParams = new URLSearchParams(queryString);

      // Obtener el valor del parámetro 'stringBusqueda'
      const stringBusqueda = urlParams.get('stringBusqueda');

      const allObrcodsString = `(${this.resultados.hits.hits.map(item => item._source.doc.obrcod).join(',')})`;

      // Construir la URL con el parámetro 'stringBusqueda'
      const url = `/get-prices-opensearch?obrcods=${allObrcodsString}`;

      fetch(url, {
            method: 'GET',
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al recibir los precios actualizados');
            }
            return response.json(); 
        })
        .then(data => {
            this.precios = data; 
            this.loadingPrice = false;
        })
        .catch(error => {
            this.error = error;
            this.loadingPrice = false;
        });
    }
  },
}
</script>

<style>
.scroll-container {
  height: 100vh;
  overflow-y: auto;
}

.loading-spinner {
  text-align: center;
  padding: 20px;
}
</style>
