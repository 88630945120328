<template>
  <div v-if="mostrarBusqueda">
    <div class="mb-4 row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12"></div>
      <div id="school-search-container" class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex">
        <input type="text" @input="buttonController" ref="ref_textoBusqueda" class="modal-login-input-text school-search" :placeholder="translations.seleccionaColegioPlaceholder" v-model="textoBusqueda" @keydown.enter.prevent>
        <button id="school-search-button" @click="buscarColegios()" type="button" class="btn btn-warning btn-block buscar-ppal school-button-search mm-disabled"><img class="lupa-icon me-0" src="/img/icons/lupa.png" > Buscar</button>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4"></div>                  
    </div>


    <div id="modalColegios"  class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content m-auto">        
            <div class="header-list p-4 d-flex align-items-center justify-content-center">
                <div class="close-modal t-0" @click="closeModal()" >  
                    <img class="key-icon" src="/img/icons/x.png" >
                </div>  
                <div class="modal-title text-center">
                    <h4>
                      {{ translations.seleccionaColegio }}
                    </h4>
                </div>
            </div>
            <div class="modal-body px-3 mx-auto row colegios-container">
                  <div class="col-6 px-2 py-2 mx-0 colegio-row" v-for="(item, index) in colegiosBusqueda" :key="index">
                    <b-button block class="col-12 px-5" @click="seleccionarColegio(item)">
                      <strong>{{ item.nombre }}</strong><br> <span>{{ item.direccion }}</span>
                    </b-button>
                  </div>                                             
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
    import toastr from 'toastr'
    import Multiselect from 'vue-multiselect'
    import {
      BCol, BFormGroup, BFormInput, BRow,BButton
    } from 'bootstrap-vue'    
    export default {
        components: {
        BFormInput,
        BFormGroup,
        BRow,
        BCol,     
        BButton, 
          Multiselect,
        },        
        props: {
            translations: Object,
            csrf: String, // Define la propiedad 'translations' como un objeto
        },   
        data() {
            return {
                selectedComunidad:null,
                colegios:null,
                colegiosBusqueda: null,
                textoBusqueda: null,
                mostrarBusqueda: false
             }
        },     
        mounted() {
          if(window.location.href.indexOf("cuenta/editar") > -1 || window.location.href.indexOf("addData=1") > -1) {
            this.mostrarBusqueda = true;
          }
        },
        methods : {
            buttonController(){
              let buttonSearch = document.getElementById('school-search-button')
              if(this.textoBusqueda.length == 0) {
                buttonSearch.classList.add('mm-disabled')
              }else{
                buttonSearch.classList.remove('mm-disabled')
              }
            },
            seleccionarColegio(item){
              document.getElementById("nombreColegio").value=item.nombre +' - ' + item.direccion;
              document.getElementById("idColegio").value=item.identificacion_id;
              document.getElementById('school-search-container').classList.add("d-none");
              document.getElementById('selectedColegioContainer').classList.remove("d-none");
              if(typeof checkIfInputsAreEmpty === 'function'){
                checkIfInputsAreEmpty()
              }
              if(document.getElementById('cuenta-section-ayuda') != null){
                document.getElementById('cuenta-section-ayuda').classList.add("d-none");
              }
              if(document.getElementById('cuenta-boton-guardar') != null){
                document.getElementById('cuenta-boton-guardar').classList.remove("mm-disabled");
              }
              this.textoBusqueda = '';
              document.getElementById('modalColegios').style.display = 'none';
              document.body.style.overflow = 'auto';
            },
            buscarColegios(){
              const schoolSearch = document.getElementById('school-search-container');
              const buttonSearch = document.getElementById('school-search-button');
              const originalText = buttonSearch.innerHTML;
              if(schoolSearch){
                buttonSearch.innerHTML = '<span class="loader"></span>';
                schoolSearch.classList.add('mm-disabled')
              }
              self = this;
                axios.get('/getColegios',{
                    headers: {
                        //token: getAcessToken()
                    },
                    params: {
                        cp: self.textoBusqueda
                    }                    
                })
                .then(response => {
                  schoolSearch.classList.remove('mm-disabled')
                  buttonSearch.innerHTML = originalText;
                  if(response!==null){
                    this.colegiosBusqueda = response.data.data;
                    if( typeof this.colegiosBusqueda !== 'undefined' && this.colegiosBusqueda.length === 0 ) {
                        toastr.warning(this.translations.noSeHanEncontradoColegios, this.translations.aviso, {timeOut: 5000})
                    }else{
                      document.getElementById('modalColegios').style.display = 'block';
                      document.body.style.overflow = 'hidden';                    
                    }
                  }
                })
                .catch(e => {
                  schoolSearch.classList.remove('mm-disabled')
                  buttonSearch.innerHTML = originalText;
                  toastr.warning(this.translations.noSeHanEncontradoColegios, this.translations.aviso, {timeOut: 5000})
                });              
            },
            closeModal(){
              document.getElementById('modalColegios').style.display = 'none';
              document.body.style.overflow = 'auto';
            }
        }
    }
</script>
<style lang="scss">
@import '/node_modules/vue-multiselect/dist/vue-multiselect.min.css';
</style>
