<template>
    <div class="col-12 col-sm-12 col-md-6 d-flex p-2">

        <div class="card p-2">
            <div class="row">
                <div class="col-3">
                    <div clas="buscados-portada">
                        <a :href="'/ficha/'+obra.obrcod">
                            <img class="buscador-portada-img"  fluid v-if="obra && obra.url_portada" @error="handleImageError" :src="obra.url_portada">
                            <img class="buscador-portada-img"  fluid v-else src="/img/noImage.png">
                        </a>
                    </div>

                </div>
                <div  class="col-9 text-start">
                    <div style="" class="buscados-card-titulo text-start mb-2">
                        <a :href="'/ficha/'+obra.obrcod">
                            {{ obra.titulo }}
                        </a>
                    </div>
                    <div class="buscados-card-colec text-start">{{translations.coleccion}} {{obra.coleccion}}</div>
                    <div class="buscados-card-editorial  text-start mb-3">{{translations.editorial}} {{ obra.sello_desc }}</div>
                    <div class="buscados-card-precio text-start d-flex">
                        <span class="teacher-price-text d-flex align-items-center justify-content-center" v-if="obra.tipo=='D' && domain=='portal'">{{translations.precioEspecial}}</span>
                        <span v-else>{{ obra.precio.toFixed(2).toString().replace('.', ',') }} €</span>
                        <div class="d-flex dDigital" v-if="obra.tipo=='D' && domain=='portal'">
                            <img class="imgDigital" src="/img/icons/digital.svg">
                            <span class="txtDigital">Digital</span>
                        </div>
                        <div class="d-flex dPapel" v-if="obra.tipo=='P' && domain=='portal'">
                            <img class="imgPapel" src="/img/icons/papel.svg">
                            <span class="txtPapel">Papel</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!(domain === 'portal' && obra.tipo=='P' && obra.stock < min_stock)" class="row mtmin-2">
                <div class="d-flex align-items-center justify-content-center col-3 buscador-contador">
                    <input-spinner ref="cantidad"></input-spinner>
                </div>
                <div class="d-flex align-items-center justify-content-center col-9 buscados-div-button">
                    <button @click="addCarrito()" class="btn btn-warning bg-anaya w-100 buscados-button d-flex align-items-center justify-content-center" >
                    <img class="buscados-shopping-cart mr-2" src="/img/icons/shopping-cart-white.png" >
                    {{translations.addCarrito}}
                </button>
                </div>
            </div>
            <div v-if="domain === 'portal' && obra.tipo=='P' && obra.stock < min_stock" class="row mtmin-2">
                <div class="d-flex align-items-center justify-content-center col-3 buscador-contador">
                </div>
                <div v-if="!realizandoCompra" class="d-flex align-items-center justify-content-center col-9 buscados-div-button">
                    <button v-if="!avisoCreado()" @click="avisoDisponibilidad()" class="btn bg-black w-100 buscados-button d-flex align-items-center justify-content-center" >
                        <img class="buscados-shopping-cart mr-2" src="/img/icons/fi_mail.png" >
                        {{translations.avisameDisponibilidad}}
                    </button>
                    <button v-if="avisoCreado()" class="btn bg-black w-100 buscados-button d-flex align-items-center justify-content-center" >
                        {{translations.avisoYaSolicitado}}
                    </button>
                </div>
                <div class="d-flex align-items-center justify-content-center col-9 buscados-div-button" v-if="realizandoCompra">
                    <b-spinner></b-spinner>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import toastr from 'toastr'
import {
    BSpinner
} from 'bootstrap-vue';
    export default {
        components: {
            BSpinner
        },
        props: {
            translations: Object,
            bookResult: Object,
            obra: null,
            domain: null,
            avisos: null
        },

        data() {
            return {
                min_stock: process.env.MIX_MIN_STOCK,
                realizandoCompra: false
            }
        },
        mounted() {
            this.min_stock=process.env.MIX_MIN_STOCK
        },
          methods: {
            async addCarrito() {
                var self = this;
                axios.get('/modificarCarrito',{
                    headers: {
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                        //token: getAcessToken()
                    },
                    params: {
                        modo: 'add',
                        obrcod: self.obra.obrcod,
                        obrodcom: self.obra.codigo,
                        ejemplares: self.$refs.cantidad.cantidad,
                        precio_euros: self.obra.precio,
                        url_portada: self.obra.url_portada,
                        obrtit: self.obra.titulo,
                        tipo: self.obra.tipo
                    }
                })
                .then(response => {
                    if(!response.data.error){
                        EventBus.$emit('actualizarCarrito', response.data.carrito);
                        window.puntoCarrito();
                    }else{
                        toastr.warning(response.data.error, this.translations.aviso, {timeOut: 5000})
                    }
                })
                .catch(e => {
                    //TODO: Redirigir error
                });
            },

            async avisoDisponibilidad() {
                var self = this;
                this.realizandoCompra = true;
                axios.get('/setAviso',{
                    headers: {
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                        //token: getAcessToken()
                    },
                    params: {
                        obrcod: self.obra.obrcod
                    }
                })
                .then(response => {
                    if(!response.data.error){
                        self.avisos = response.data;
                        toastr.success('El aviso de disponibilidad se ha creado correctamente', '¡Aviso creado!', {timeOut: 5000})
                        self.realizandoCompra = false;
                    }else{
                        toastr.error('Se ha producido un error al crear el aviso', '¡Error!', {timeOut: 5000})
                        self.realizandoCompra = false;
                    }
                })
                .catch(e => {
                    self.realizandoCompra = false;
                });
            },
            handleImageError(event) {
               event.target.src = '/img/noImage.png'; // URL de la imagen alternativa
            },
            avisoCreado(){
                var avisoCreado = false;
                var self = this;
                if (this.avisos) {
                    this.avisos.forEach(obrcodAviso => {
                    if (self.obra.obrcod == obrcodAviso){
                        avisoCreado = true;
                    }
                    });
                }
                return avisoCreado;
            }
          },
    }
</script>
