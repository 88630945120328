<template>
  <div class="container">
    <div class="listado-resultados pt-5 row">

        <card-mas-buscados :translations="translations" v-for="(item, index) in resultados.catalogo" :key="index" :obra=item :domain=domain :avisos=avisos></card-mas-buscados>

        <div v-if="resultados.catalogo.length == 0 " class="no-informacion">
          {{translations.noResultados}}
        </div>
    </div>
    <div class="mt-5 mb-5">
      <b-pagination
          v-model="resultados.pagina"
          pills
          first-number
          last-number
          :per-page="resultados.registrosPagina"
          :total-rows="resultados.encontrados"
          size="sm"
          @change="GoToPage"
          align="center"
      >
        <template #prev-text>
          <feather-icon
              name="chevron-left"
              size="15"
              class="stroke-current"
          />
        </template>
        <template #next-text>
          <feather-icon
              name="chevron-right"
              size="15"
              class="stroke-current"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>

import {
  BCol, BRow, BPagination,
} from 'bootstrap-vue'
import FeatherIcon from 'feather-icons-vue';


export default {
  components: {
    BCol,
    BRow,
    BPagination,
    FeatherIcon,
  },
  props: {
      translations: Object,
      resultados: null,
      domain: null,
      avisos: null
  },

  data(){
        return{
          currentPage: this.resultados.pagina,
          perPage: this.resultados.registrosPagina,
          rows: this.resultados.encontrados,
        }
  },
  methods: {
    GoToPage(page) {
      if (window.location.search) {
        var query = this.updateQueryStringParameter(window.location.search,'page',page);
        window.location = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
      } else {
        window.location = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + page;
      }
    },
    updateQueryStringParameter(uri, key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf('?') !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
      }
      else {
        return uri + separator + key + "=" + value;
      }
    }
  },
}
</script>
