<template>
    <div id="busqueda-avanzada" class="div-busqueda row mt-4">
        <form  :action="isLecturas ? 'lecturas-recomendadas' : 'catalogo'" method="GET" class="form-group row">
            <input type="hidden" name="type" value="avanzada">
            <div class="col-12 col-sm-12 col-md-12" v-bind:class="{ 'col-lg-4': domain && domain=='portal', 'col-lg-6': domain && domain!='portal'}">
                <div class="buscar-avanzada-headers mb-2">
                    {{ translations.busquedaPortada }}
                </div>
                <div class="mb-4">
                    <input type="text" name="filtroTituloAutor" class="form-control" aria-label="Small" :placeholder="translations.introduzcaTituloOautor">
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12" v-bind:class="{ 'col-lg-4': domain && domain=='portal', 'col-lg-6': domain && domain!='portal'}">
                <div class="buscar-avanzada-headers mb-2">
                    {{ translations.busquedaEAN }}
                </div>
                <div class="mb-4">
                     <input type="text" name="filtroEANISBN" class="form-control" aria-label="Small" :placeholder="translations.introduzcaCodigo">
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-4" v-if="domain && domain=='portal'">
                <div class="buscar-avanzada-headers mb-2">
                    {{ translations.formato }}
                </div>
                <!--<div class="mb-4">
                    <input type="text" name="filtroFormato" class="form-control" aria-label="Small" :placeholder="translations.introduzcaCodigo">
                </div>-->
                <div class="mb-4">
                  <input type="hidden" name="filtroFormato" id="select-formato">
                  <div class="div-parent-multiselect">
                      <multiselect
                       class="select-buscador"
                        v-model="selectedFormato"
                        :options="formatos"
                        :multiple="false"
                        :placeholder="translations.introduzcaFormato"
                        :show-labels="false"
                        no-options="Sin resultados"
                        no-result="Sin resultados"
                        :taggable="false"
                        :loading="isLoadingFormato"
                        @input="(value) => handleInputSearchForm( value ? value:null, 'select-formato')"
                      >
                        <span slot="noResult">{{translations.sinOpcionesBusqueda}}</span>
                      </multiselect>
                  </div>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-4 ">
                <div class="buscar-avanzada-headers mb-2">
                    {{ translations.busquedaSello }}
                </div>
                <div class="mb-4">
                  <input type="hidden" name="filtroSello" id="select-sello">
                  <div class="div-parent-multiselect">
                      <multiselect
                       class="select-buscador"
                        v-model="selectedSello"
                        :options="sellos"
                        :multiple="false"
                        label="editorial_desc"
                        track-by="editorial_id"
                        :placeholder="translations.introduzcaSello"
                        :show-labels="false"
                        no-options="Sin resultados"
                        no-result="Sin resultados"
                        :taggable="false"
                        :loading="isLoadingSello"
                        @input="(value) => handleInputSearchForm( value ? value.editorial_id:null, 'select-sello')"
                      >
                        <span slot="noResult">{{translations.sinOpcionesBusqueda}}</span>
                      </multiselect>
                  </div>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-4 ">
                <div class="buscar-avanzada-headers mb-2">
                    {{ translations.busquedaSerie }}
                </div>
                <div class="mb-4">
                    <input type="hidden" name="filtroSerie" id="filtroSerie">
                    <div class="div-parent-multiselect">
                        <multiselect
                         class="select-buscador"
                          v-model="selectedSerie"
                          :options="series"
                          :multiple="false"
                          label="serie"
                          track-by="serie"
                          :placeholder="translations.introduzcaSerie"
                          :show-labels="false"
                          no-options="Sin resultados"
                          no-result="Sin resultados"
                          :taggable="false"
                          :loading="isLoadingSerie"
                          @input="(value) => handleInputSearchForm( value ? value.serie:null, 'filtroSerie')"
                        >
                          <span slot="noResult">{{translations.sinOpcionesBusqueda}}</span>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="buscar-avanzada-headers mb-2">
                    {{ translations.busquedaComunidad }}
                </div>
                <div class="mb-4">
                    <input type="hidden" name="filtroCCAA" id="filtroCCAA">
                    <div class="div-parent-multiselect">
                        <multiselect
                         class="select-buscador"
                          v-model="selectedComunidad"
                          :options="autonomias"
                          :multiple="false"
                          label="autonomia"
                          track-by="autonomia"
                          :placeholder="translations.introduzcaComunidad"
                          :show-labels="false"
                          no-options="Sin resultados"
                          no-result="Sin resultados"
                          :taggable="false"
                          :required="true"
                          :allow-empty="false"
                          :loading="isLoadingComunidad"
                          @input="(value) => handleInputSearchForm( value ? value.autonomia:null, 'filtroCCAA')"
                        >
                          <span slot="noResult">{{translations.sinOpcionesBusqueda}}</span>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="buscar-avanzada-headers mb-2">
                    {{ translations.busquedaEtapa }}
                </div>
                <div class="mb-4">

                    <input type="hidden" name="filtroEtapa" id="filtroEtapa">
                    <div class="div-parent-multiselect">
                        <multiselect
                         class="select-buscador"
                          v-model="selectedEtapa"
                          :options="optionsEtapa"
                          :multiple="false"
                          label="name"
                          track-by="value"
                          :placeholder="translations.introduzcaEtapa"
                          :show-labels="false"
                          no-options="Sin resultados"
                          no-result="Sin resultados"
                          :taggable="false"
                          :loading="isLoadingEtapa"
                          @input="(value) => handleInputSearchForm( value ? value.value:null, 'filtroEtapa')"
                        >
                          <span slot="noResult">{{translations.sinOpcionesBusqueda}}</span>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="buscar-avanzada-headers mb-2">
                    {{ translations.busquedaCiclo }}
                </div>
                <div class="mb-4">
                    <input type="hidden" name="filtroCiclo" id="filtroCiclo">
                    <div class="div-parent-multiselect">
                        <multiselect
                        :disabled="ciclodisabled"
                         class="select-buscador"
                          v-model="selectedCiclo"
                          :options="optionsCiclo"
                          :multiple="false"
                          label="name"
                          track-by="value"
                          :placeholder="translations.introduzcaCiclo"
                          :show-labels="false"
                          no-options="Sin resultados"
                          no-result="Sin resultados"
                          :taggable="false"
                          :loading="isLoadingCiclo"
                          @input="(value) => handleInputSearchForm( value ? value.value:null, 'filtroCiclo')"
                        >
                          <span slot="noResult">{{translations.sinOpcionesBusqueda}}</span>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="buscar-avanzada-headers mb-2">
                    {{ translations.busquedaCurso }}
                </div>
                <div class="mb-4">

                    <input type="hidden" name="filtroCurso" id="filtroCurso">
                    <div class="div-parent-multiselect">
                        <multiselect
                        :disabled="cursodisabled"
                         class="select-buscador"
                          v-model="selectedCurso"
                          :options="optionsCurso"
                          :multiple="false"
                          label="name"
                          track-by="value"
                          :placeholder="translations.introduzcaCurso"
                          :show-labels="false"
                          no-options="Sin resultados"
                          no-result="Sin resultados"
                          :taggable="false"
                          :loading="isLoadingCurso"
                          @input="(value) => handleInputSearchForm( value ? value.value:null, 'filtroCurso')"
                        >
                          <span slot="noResult">{{translations.sinOpcionesBusqueda}}</span>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 ">
                <div class="buscar-avanzada-headers mb-2">
                    {{ translations.busquedaAsignatura }}
                </div>
                <div class="mb-4">

                    <input type="hidden" name="filtroAsignatura" id="filtroAsignatura">
                    <div class="div-parent-multiselect">
                        <multiselect
                         class="select-buscador"
                          v-model="selectedAsignatura"
                          :options="asignaturas"
                          :multiple="false"
                          label="asignatura_desc"
                          track-by="asignatura_id"
                          :placeholder="translations.busquedaAsignatura"
                          :show-labels="false"
                          no-options="Sin resultados"
                          no-result="Sin resultados"
                          :taggable="false"
                          :loading="isLoadingAsignatura"
                          @input="(value) => handleInputSearchForm( value ? value.asignatura_id:null, 'filtroAsignatura')"
                        >
                          <span slot="noResult">{{translations.sinOpcionesBusqueda}}</span>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 ">
                <div class="buscar-avanzada-headers mb-2">
                {{ translations.busquedaIdioma }}
                </div>
                <div class="mb-4">

                    <input type="hidden" name="filtroIdioma" id="filtroIdioma">
                    <div class="div-parent-multiselect">
                        <multiselect
                         class="select-buscador"
                          v-model="selectedIdioma"
                          :options="idiomas"
                          :multiple="false"
                          label="idioma"
                          track-by="idioma"
                          :placeholder="translations.busquedaIdioma"
                          :show-labels="false"
                          no-options="Sin resultados"
                          no-result="Sin resultados"
                          :taggable="false"
                          :loading="isLoadingIdioma"
                          @input="(value) => handleInputSearchForm( value ? value.idioma:null, 'filtroIdioma')"
                        >
                          <span slot="noResult">{{translations.sinOpcionesBusqueda}}</span>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center">
                <button type="submit" class="btn btn-warning btn-block btn-lg w-25 buscar-avanzada">{{translations.buscar}}</button>
            </div>
        </form>
    </div>
</template>

<script>

    import Multiselect from 'vue-multiselect'
    import {
      BCol, BFormGroup, BFormInput, BRow,
    } from 'bootstrap-vue'
    export default {
        components: {
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
          Multiselect,
        },
        props: {
            translations: Object, // Define la propiedad 'translations' como un objeto
            autonomia: null,
            domain: null,
            isLecturas: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isLoadingFormato: false,
                formatos:['Todos','Digital','Papel'],
                selectedFormato: null,
                sellos:[],
                isLoadingSello: false,
                selectedSello:null,
                isLoadingSerie: false,
                series: [],
                selectedSerie:null,
                isLoadingComunidad:false,
                autonomias: [],
                selectedComunidad:null,
                isLoadingAsignatura:false,
                asignaturas: [],
                selectedAsignatura:null,
                isLoadingIdioma:false,
                idiomas: [],
                selectedIdioma:null,
                etcicu: [],
                isLoadingEtapa:false,
                optionsEtapa: [],
                selectedEtapa: "",
                isLoadingCiclo:false,
                ciclos:[],
                optionsCiclo: [],
                selectedCiclo: "",
                ciclodisabled: true,
                isLoadingCurso:false,
                cursos:[],
                optionsCurso: [],
                selectedCurso: "",
                cursodisabled: true,
            }
        },
        mounted() {
            this.cargarEtapas();
            this.cargarCiclosWS();
            this.cargarCursosWS();
            this.cargarSellos();
            this.cargarSeries();
            this.cargarAutonomias();
            this.cargarAsignaturas();
            this.cargarIdiomas();

            this.selectedComunidad = this.autonomias.find(option => option.autonomia === this.autonomia)
            const urlParams = new URLSearchParams(window.location.search);
            this.filtroCCAA = urlParams.get('filtroCCAA');
            let selectedComunidad = this.autonomia;
            if( this.filtroCCAA != null) {
                selectedComunidad = (typeof this.filtroCCAA !== 'undefined' && this.filtroCCAA.length > 0) ? this.filtroCCAA : this.autonomia;
            }

            this.selectedComunidad = {
                autonomia: selectedComunidad
            };
            this.handleInputSearchForm( selectedComunidad, 'filtroCCAA')
        },
        methods : {
            submit : function(){
                window.location.href("")
                router.push({ path: 'catalogo', query: { plan: 'private' } })
            },
            cargarEtapas() {
                this.isLoadingEtapa=true;
                var self = this;
                let dataEtapas = localStorage.getItem('dataEtapas');
                if(dataEtapas != null && typeof dataEtapas != undefined && dataEtapas != 'undefined') {
                  self.optionsEtapa = JSON.parse(dataEtapas);
                  self.isLoadingEtapa=false;
                } else {
                    axios.get('/getEtapas',{
                        headers: {
                            //token: getAcessToken()
                        },
                        params: {}
                    })
                    .then(response => {
                        self.optionsEtapa = Object.values(response.data.data).map(obj => ({ value: obj.etapa_id, name: obj.etapa_desc }))
                        self.isLoadingEtapa=false;
                        localStorage.setItem('dataEtapas', JSON.stringify(self.optionsEtapa));
                    })
                    .catch(e => {
                        //TODO: Redirigir error
                    });
                }
            },
            cargarCiclos(etapa) {
                document.getElementById('filtroCiclo').value=""
                document.getElementById('filtroCurso').value=""
                this.ciclodisabled = true;
                this.cursodisabled = true;
                this.selectedCiclo = "";
                this.selectedCurso = "";
                this.optionsCiclo = [];
                this.optionsCurso = [];
                if(etapa!==null){
                  /*this.optionsCiclo = Object.values(Object.values(this.ciclos).filter(obj=>obj.Id==etapa)[0].Ciclos).map(function (obj) {
                      return {"value": obj.Id,"name": obj.Denominacion};
                  });*/
                  this.optionsCiclo = Object.values(this.ciclos).filter(obj=>obj.etapa_id==etapa).map(function (obj) {
                      return {"value": obj.ciclo_id,"name": obj.ciclo_desc};
                  })
                  this.optionsCiclo.sort((x, y) => x.name.localeCompare(y.name));
                  this.ciclodisabled = false;
                }

            },
            cargarCursos(ciclo) {
                document.getElementById('filtroCurso').value=""
                this.selectedCurso = ""
                this.cursodisabled = true;
                this.optionsCurso = [];
                if(ciclo!==null){
                  this.optionsCurso = Object.values(this.cursos).filter(obj=>obj.ciclo_id==ciclo).map(function (obj) {
                      return {"value": obj.curso_id,"name": obj.curso_desc};
                  })
                  this.optionsCurso.sort((x, y) => x.name.localeCompare(y.name));
                  this.cursodisabled = false;
                }

            },
            cargarSellos(){
                this.isLoadingSello=true;
                var self = this;
                let dataSellos = localStorage.getItem('dataSellos');
                if(dataSellos != null && typeof dataSellos != undefined && dataSellos != 'undefined') {
                  self.sellos = JSON.parse(dataSellos);
                  self.isLoadingSello=false;
                } else {
                    axios.get('/getEditoriales',{
                        headers: {
                            //token: getAcessToken()
                        },
                        params: {}
                    })
                    .then(response => {
                        self.sellos = response.data.data
                        self.isLoadingSello=false;
                        localStorage.setItem('dataSellos', JSON.stringify(self.sellos));
                    })
                    .catch(e => {
                        //TODO: Redirigir error
                    });
                }
            },
            cargarSeries(){
                this.isLoadingSerie=true;
                var self = this;
                let dataSeries = localStorage.getItem('dataSeries');
                if(dataSeries != null && typeof dataSeries != undefined && dataSeries != 'undefined') {
                  self.series = JSON.parse(dataSeries);
                  self.isLoadingSerie=false;
                } else {
                    axios.get('/getSeries',{
                        headers: {
                            //token: getAcessToken()
                        },
                        params: {}
                    })
                    .then(response => {
                        self.series = response.data.data
                        self.isLoadingSerie=false;
                        localStorage.setItem('dataSeries', JSON.stringify(self.series));
                    })
                    .catch(e => {
                        //TODO: Redirigir error
                    });
                }
            },
            cargarAutonomias(){
                this.isLoadingComunidad=true;
                var self = this;
                let dataAutonomias = localStorage.getItem('dataAutonomias');
                if(dataAutonomias != null && typeof dataAutonomias != undefined && dataAutonomias != 'undefined') {
                  self.autonomias = JSON.parse(dataAutonomias);
                  self.isLoadingComunidad=false;
                } else {
                    axios.get('/getAutonomias',{
                        headers: {
                            //token: getAcessToken()
                        },
                        params: {}
                    })
                    .then(response => {
                        self.autonomias = response.data.data
                        self.isLoadingComunidad=false;
                        localStorage.setItem('dataAutonomias', JSON.stringify(self.autonomias));
                    })
                    .catch(e => {
                        //TODO: Redirigir error
                    });
                }
            },
            cargarAsignaturas(){
                this.isLoadingAsignatura=true;
                var self = this;
                let dataAsignaturas = localStorage.getItem('dataAsignaturas');
                if(dataAsignaturas != null && typeof dataAsignaturas != undefined && dataAsignaturas != 'undefined') {
                  self.asignaturas = JSON.parse(dataAsignaturas);
                  self.isLoadingAsignatura=false;
                } else {
                    axios.get('/getAsignaturas',{
                        headers: {
                            //token: getAcessToken()
                        },
                        params: {}
                    })
                    .then(response => {
                        self.asignaturas = response.data.data
                        self.isLoadingAsignatura=false;
                        localStorage.setItem('dataAsignaturas', JSON.stringify(self.asignaturas));
                    })
                    .catch(e => {
                        //TODO: Redirigir error
                    });
                }
            },
            cargarIdiomas(){
                this.isLoadingIdioma=true;
                var self = this;
                let dataIdiomas = localStorage.getItem('dataIdiomas');
                if(dataIdiomas != null && typeof dataIdiomas != undefined && dataIdiomas != 'undefined') {
                  self.idiomas = JSON.parse(dataIdiomas);
                  self.isLoadingIdioma=false;
                } else {
                    axios.get('/getIdiomas',{
                        headers: {
                            //token: getAcessToken()
                        },
                        params: {}
                    })
                    .then(response => {
                        self.idiomas = response.data.data
                        self.isLoadingIdioma=false;
                        localStorage.setItem('dataIdiomas', JSON.stringify(self.idiomas));
                    })
                    .catch(e => {
                        //TODO: Redirigir error
                    });
                }
            },
            /*cargarEtapas(){
                var self = this;
                axios.get('/getEtapas',{
                    headers: {
                        //token: getAcessToken()
                    },
                    params: {}
                })
                .then(response => {
                    self.optionsEtapa = response.data.data
                })
                .catch(e => {
                    //TODO: Redirigir error
                });
            },*/
            cargarCiclosWS(){
                this.isLoadingCiclo=true;
                var self = this;
                let dataCiclos = localStorage.getItem('dataCiclos');
                if(dataCiclos != null && typeof dataCiclos != undefined && dataCiclos != 'undefined') {
                  self.ciclos = JSON.parse(dataCiclos);
                  self.isLoadingCiclo=false;
                } else {
                    axios.get('/getCiclos',{
                        headers: {
                            //token: getAcessToken()
                        },
                        params: {}
                    })
                    .then(response => {
                        self.ciclos = response.data.data
                        self.isLoadingCiclo=false;
                        localStorage.setItem('dataCiclos', JSON.stringify(self.ciclos));
                    })
                    .catch(e => {
                        //TODO: Redirigir error
                    });
                }
            },
            cargarCursosWS(){
                this.isLoadingCurso=true;
                var self = this;
                let dataCursos = localStorage.getItem('dataCursos');
                if(dataCursos != null && typeof dataCursos != undefined && dataCursos != 'undefined') {
                  self.cursos = JSON.parse(dataCursos);
                  self.isLoadingCurso=false;
                } else {
                    axios.get('/getCursos',{
                        headers: {
                            //token: getAcessToken()
                        },
                        params: {}
                    })
                    .then(response => {
                        self.cursos = response.data.data
                        self.isLoadingCurso=false;
                        localStorage.setItem('dataCursos', JSON.stringify(self.cursos));
                    })
                    .catch(e => {
                        //TODO: Redirigir error
                    });
                }
            },
            handleInputSearchForm( value, nameInput) {
              document.getElementById(nameInput).value=value
              if(nameInput=='filtroEtapa'){
                this.cargarCiclos(value)
              }
              if(nameInput=='filtroCiclo'){
                this.cargarCursos(value)
              }

            },
        }
    }
</script>
<style lang="scss">
@import '/node_modules/vue-multiselect/dist/vue-multiselect.min.css';
</style>
