<template>
  <div>
    <multiselect
      v-model="selectedCountry"
      :options="countries"
      :searchable="true"
      :multiple="false"
      :close-on-select="true"
      :show-labels="false"
      :taggable="false"     
      :allow-empty="false"
        no-options="Sin resultados"
        no-result="Sin resultados"
      :placeholder="placeholder"
      label="name"
      track-by="code"
      @input="checkCountry"
      required
      class="select-buscador col-12 mb-4"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
    import {
      BCol, BFormGroup, BFormInput, BRow,
    } from 'bootstrap-vue'    
export default {
    components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,            
        Multiselect,
    },
    props: {
        placeholder: String,
    },
  data() {
    return {
      selectedCountry: { code: 'ES', name: 'España' },
      countries: [
        { code: 'ES', name: 'España' },
        { code: 'AF', name: 'Afganistán' },
        { code: 'AL', name: 'Albania' },
        { code: 'DZ', name: 'Argelia' },
        { code: 'AS', name: 'Samoa Americana' },
        { code: 'AD', name: 'Andorra' },
        { code: 'AO', name: 'Angola' },
        { code: 'AI', name: 'Anguilla' },
        { code: 'AQ', name: 'Antártida' },
        { code: 'AG', name: 'Antigua y Barbuda' },
        { code: 'AR', name: 'Argentina' },
        { code: 'AM', name: 'Armenia' },
        { code: 'AW', name: 'Aruba' },
        { code: 'AU', name: 'Australia' },
        { code: 'AT', name: 'Austria' },
        { code: 'AZ', name: 'Azerbaiyán' },
        { code: 'BS', name: 'Bahamas' },
        { code: 'BH', name: 'Baréin' },
        { code: 'BD', name: 'Banglades' },
        { code: 'BB', name: 'Barbados' },
        { code: 'BY', name: 'Bielorrusia' },
        { code: 'BE', name: 'Bélgica' },
        { code: 'BZ', name: 'Belice' },
        { code: 'BJ', name: 'Benin' },
        { code: 'BM', name: 'Bermudas' },
        { code: 'BT', name: 'Butan' },
        { code: 'BO', name: 'Bolivia' },
        { code: 'BA', name: 'Bosnia-Herzegovina' },
        { code: 'BW', name: 'Botsuana' },
        { code: 'BR', name: 'Brasil' },
        { code: 'IO', name: 'Territorio Británico del Océano Índico' },
        { code: 'BN', name: 'Brunei' },
        { code: 'BG', name: 'Bulgaria' },
        { code: 'BF', name: 'Burkina Faso' },
        { code: 'BI', name: 'Burundi' },
        { code: 'KH', name: 'Camboya' },
        { code: 'CM', name: 'Camerún' },
        { code: 'CA', name: 'Canadá' },
        { code: 'CV', name: 'Cabo Verde' },
        { code: 'KY', name: 'Islas Caimán' },
        { code: 'CF', name: 'República Centroafricana' },
        { code: 'TD', name: 'Chad' },
        { code: 'CL', name: 'Chile' },
        { code: 'CN', name: 'China' },
        { code: 'CX', name: 'Isla de Navidad' },
        { code: 'CC', name: 'Islas Cocos' },
        { code: 'CO', name: 'Colombia' },
        { code: 'KM', name: 'Comoras' },
        { code: 'CG', name: 'Congo' },
        { code: 'CD', name: 'República Democrática del Congo' },
        { code: 'CK', name: 'Islas Cook' },
        { code: 'CR', name: 'Costa Rica' },
        { code: 'CI', name: 'Costa de Marfil' },
        { code: 'HR', name: 'Croacia' },
        { code: 'CU', name: 'Cuba' },
        { code: 'CY', name: 'Chipre' },
        { code: 'CZ', name: 'Chequia' },
        { code: 'DK', name: 'Dinamarca' },
        { code: 'DJ', name: 'Yibuti' },
        { code: 'DM', name: 'Dominica' },
        { code: 'DO', name: 'República Dominicana' },
        { code: 'EC', name: 'Ecuador' },
        { code: 'EG', name: 'Egipto' },
        { code: 'SV', name: 'El Salvador' },
        { code: 'GQ', name: 'Guinea Ecuatorial' },
        { code: 'ER', name: 'Eritrea' },
        { code: 'EE', name: 'Estonia' },
        { code: 'ET', name: 'Etiopía' },
        { code: 'FK', name: 'Islas Malvinas' },
        { code: 'FO', name: 'Islas Feroe' },
        { code: 'FJ', name: 'Fiyi' },
        { code: 'FI', name: 'Finlandia' },
        { code: 'FR', name: 'Francia' },
        { code: 'GF', name: 'Guayana Francesa' },
        { code: 'PF', name: 'Polinesia Francesa' },
        { code: 'GA', name: 'Gabón' },
        { code: 'GM', name: 'Gambia' },
        { code: 'GE', name: 'Georgia' },
        { code: 'DE', name: 'Alemania' },
        { code: 'GH', name: 'Ghana' },
        { code: 'GI', name: 'Gibraltar' },
        { code: 'GR', name: 'Grecia' },
        { code: 'GL', name: 'Groenlandia' },
        { code: 'GD', name: 'Granada' },
        { code: 'GP', name: 'Guadalupe' },
        { code: 'GU', name: 'Guam' },
        { code: 'GT', name: 'Guatemala' },
        { code: 'GG', name: 'Guernsey' },
        { code: 'GN', name: 'Guinea' },
        { code: 'GW', name: 'Guinea-Bisau' },
        { code: 'GY', name: 'Guyana' },
        { code: 'HT', name: 'Haití' },
        { code: 'VA', name: 'Ciudad del Vaticano' },
        { code: 'HN', name: 'Honduras' },
        { code: 'HK', name: 'Hong Kong' },
        { code: 'HU', name: 'Hungría' },
        { code: 'IS', name: 'Islandia' },
        { code: 'IN', name: 'India' },
        { code: 'ID', name: 'Indonesia' },
        { code: 'IR', name: 'Irán' },
        { code: 'IQ', name: 'Iraq' },
        { code: 'IE', name: 'Irlanda' },
        { code: 'IM', name: 'Isla de Man' },
        { code: 'IL', name: 'Israel' },
        { code: 'IT', name: 'Italia' },
        { code: 'JM', name: 'Jamaica' },
        { code: 'JP', name: 'Japón' },
        { code: 'JE', name: 'Jersey' },
        { code: 'JO', name: 'Jordania' },
        { code: 'KZ', name: 'Kazajistán' },
        { code: 'KE', name: 'Kenia' },
        { code: 'KI', name: 'Kiribati' },
        { code: 'KP', name: 'Corea del Norte' },
        { code: 'KR', name: 'Corea del Sur' },
        { code: 'XK', name: 'Kosovo' },
        { code: 'KW', name: 'Kuwait' },
        { code: 'KG', name: 'Kirguistán' },
        { code: 'LA', name: 'Laos' },
        { code: 'LV', name: 'Letonia' },
        { code: 'LB', name: 'Líbano' },
        { code: 'LS', name: 'Lesoto' },
        { code: 'LR', name: 'Liberia' },
        { code: 'LY', name: 'Libia' },
        { code: 'LI', name: 'Liechtenstein' },
        { code: 'LT', name: 'Lituania' },
        { code: 'LU', name: 'Luxemburgo' },
        { code: 'MO', name: 'Macao' },
        { code: 'MK', name: 'República de Macedonia' },
        { code: 'MG', name: 'Madagascar' },
        { code: 'MW', name: 'Malaui' },
        { code: 'MY', name: 'Malasia' },
        { code: 'MV', name: 'Maldivas' },
        { code: 'ML', name: 'Malí' },
        { code: 'MT', name: 'Malta' },
        { code: 'MH', name: 'Islas Marshall' },
        { code: 'MQ', name: 'Martinica' },
        { code: 'MR', name: 'Mauritania' },
        { code: 'MU', name: 'Mauricio' },
        { code: 'YT', name: 'Mayotte' },
        { code: 'MX', name: 'México' },
        { code: 'FM', name: 'Estados Federados de Micronesia' },
        { code: 'MD', name: 'Moldavia' },
        { code: 'MC', name: 'Monaco' },
        { code: 'MN', name: 'Mongolia' },
        { code: 'ME', name: 'Montenegro' },
        { code: 'MS', name: 'Montserrat' },
        { code: 'MA', name: 'Marruecos' },
        { code: 'MZ', name: 'Mozambique' },
        { code: 'MM', name: 'Birmania' },
        { code: 'NA', name: 'Namibia' },
        { code: 'NR', name: 'Nauru' },
        { code: 'NP', name: 'Nepal' },
        { code: 'NL', name: 'Holanda' },
        { code: 'AN', name: 'Antillas Holandesas' },
        { code: 'NC', name: 'Nueva Caledonia' },
        { code: 'NZ', name: 'Nueva Zelanda' },
        { code: 'NI', name: 'Nicaragua' },
        { code: 'NE', name: 'Niger' },
        { code: 'NG', name: 'Nigeria' },
        { code: 'NU', name: 'Niue' },
        { code: 'NF', name: 'IslaNorfolk' },
        { code: 'MP', name: 'IslasMarianasdelNorte' },
        { code: 'NO', name: 'Noruega' },
        { code: 'OM', name: 'Omán' },
        { code: 'PK', name: 'Pakistán' },
        { code: 'PW', name: 'Palaos' },
        { code: 'PA', name: 'Panamá' },
        { code: 'PG', name: 'Papúa Nueva Guinea' },
        { code: 'PY', name: 'Paraguay' },
        { code: 'PE', name: 'Perú' },
        { code: 'PH', name: 'Filipinas' },
        { code: 'PN', name: 'Islas Pitcairn' },
        { code: 'PL', name: 'Polonia' },
        { code: 'PT', name: 'Portugal' }
      ]
    };
  },
  methods: {
        handleInputSearchForm(value, nameInput) {  
            document.getElementById('error-comunidad').classList.add('d-none') 
            document.getElementById(nameInput).value=value; 
            console.log(document.getElementById(nameInput).value)   
        },
        checkCountry(selectedOption){
            const ceutaCountries = ['PL','PT','NL','MA','LU','IT','IE','HU','GR','DE','FR','CO','BE','AD','AL'];
            if(selectedOption.code === 'ES'){
                document.getElementById('autonomiaSelectorContainer').classList.remove('d-none');
                this.handleInputSearchForm( '', 'filtroCCAAUsuario'); 
            }else{
                document.getElementById('autonomiaSelectorContainer').classList.add('d-none');
                if(ceutaCountries.includes(selectedOption.code)){
                    this.handleInputSearchForm( 'CEUTA', 'filtroCCAAUsuario');                
                }else{
                    this.handleInputSearchForm( 'COMUNIDAD DE MADRID', 'filtroCCAAUsuario');                
                }
            }
        }
  }
};
</script>
