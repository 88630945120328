<template>
  <div class="mm-input-spinner d-flex justify-content-center">
    <span  type="button" class="buscados-spinner-button pb-less"  @click="modificarCantidad('restar')">
      -
    </span>
    <input class="input-cantidad" disabled v-model="obra.ejemplares">
    <span  type="button" class="buscados-spinner-button"  @click="modificarCantidad('sumar')">
      +
    </span>
  </div>
</template>

<script>
import toastr from 'toastr'
export default {
  name: 'InputSpinnerResumen',
  props: {
            obra:null
        },
  data(){
        return{
          valor: 0
        }
  },
  mounted(){
      const quantityButtons = document.querySelectorAll('.buscados-spinner-button');
      quantityButtons.forEach(button => {
        button.classList.remove('mm-disabled');
      })
  },
  methods: {
      modificarCantidad(modificando) {    
        const quantityButtons = document.querySelectorAll('.buscados-spinner-button');
        quantityButtons.forEach(button => {
          button.classList.add('mm-disabled');
        });
        let numEjemplaresInicial = this.valor;
        if (modificando =='sumar') {
          this.valor = this.valor + 1;
        }
        if (modificando =='restar') {
          this.valor-1 == 0 ? this.valor=0 : this.valor=this.valor-1
        }
          var self = this;
          if(self.obra.importe === undefined || self.obra.importe === null ) {
            self.obra['importe'] = parseFloat(self.obra.precio_euros).toFixed(2);
            self.obra['titulo'] = self.obra.obrtit;
          }else{
            self.obra.importe = self.obra.importe / numEjemplaresInicial;
          }
          self.obra.importe = parseFloat(self.obra.importe).toFixed(2)

          axios.get('/modificarCarrito',{
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
              //token: getAcessToken()
            },
            params: {
              modo: 'set',
              obrcod: self.obra.obrcod,
              obrodcom: self.obra.obrodcom,
              ejemplares: self.valor,
              precio_euros: self.obra.importe,
              url_portada: self.obra.url_portada,
              obrtit: self.obra.titulo,
              tipo: self.obra.tipo
            }
          })
          .then(response => {
            
            if(!response.data.error){
                EventBus.$emit('actualizarCarrito', response.data.carrito);
                if (window.location.pathname === '/resumen-carrito') {
                  window.location.reload();
                } 
            }else{
                toastr.warning(response.data.error, {timeOut: 5000})
                quantityButtons.forEach(button => {
                  button.classList.remove('mm-disabled');
                })
            }            
          })
          .catch(e => {
            //TODO: Redirigir error
          });
      },
  },
  mounted () {
    this.valor = this.obra.ejemplares;
  }
}
</script>

<style scoped lang="scss">
  .mm-input-spinner {
    input {
      border: none;
      padding: 0;
      width: 30px;
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      text-align: center;
      height: 18px;
    }
    button {
      height: 18px;
      padding: 1px 2px;
      line-height: 12px;
      svg {
        stroke-width: 2.5;
      }
    }

    .input-cantidad{
      margin-top: auto;
      margin-bottom:auto;
      background-color: transparent;
    }
  }
</style>
