<template>
  <div class="swiper-container overflow-hidden">
    <div class="swiper-wrapper">
      <div class="swiper-slide card col-lg-2 col-md-4 col-12 shadow-none" v-for="(obra, index) in obras" :key="index">
        <div class="obra-item">
          <div class="row">
                <div class="col-12">
                    <div class="book-slider-portada d-flex justify-content-center">
                        <a :href="'/ficha/'+obra.obrcod">
                            <img class=""  fluid v-if="obra && obra.url_portada" @error="handleImageError" :src="obra.url_portada">
                            <img class=""  fluid v-else src="/img/noImage.png">
                        </a>
                    </div>
                </div>
                <div  class="col-12 my-3">
                    <div style="" class="text-center mb-2 book-slider-title px-3">
                        <a :href="'/ficha/'+obra.obrcod">
                            {{ obra.obrtit }}
                        </a>
                    </div>
                    <div class="buscados-card-precio text-center d-flex justify-content-center">
                        <!-- <span class="teacher-price-text d-flex align-items-center justify-content-center" v-if="obra.tipo=='D' && domain=='portal'">{{translations.precioEspecial}}</span>
                        <span class="book-slider-price" v-else>{{ obra.precio.toFixed(2).toString().replace('.', ',') }} €</span>                    -->
                        <!-- <div class="d-flex dDigital" v-if="obra.tipo=='D' && domain=='portal'">
                            <img class="imgDigital" src="/img/icons/digital.svg">
                            <span class="txtDigital">Digital</span>
                        </div>
                        <div class="d-flex dPapel" v-if="obra.tipo=='P' && domain=='portal'">
                            <img class="imgPapel" src="/img/icons/papel.svg">
                            <span class="txtPapel">Papel</span>
                        </div> -->
                    </div>
                </div>
            </div>
            
            <!-- <div v-if="!(domain === 'portal' && obra.tipo=='P' && obra.stock < min_stock)" class="row mtmin-2 d-flex justify-content-center"> -->
                <!-- <div class="d-flex align-items-center justify-content-center col-9 buscados-div-button">
                    <button @click="addCarrito()" class="btn btn-warning bg-anaya w-100 buscados-button d-flex align-items-center justify-content-center p-0" >
                    <img class="buscados-shopping-cart mr-2" src="/img/icons/shopping-cart-white.png" >
                    {{translations.addCarrito}}
                </button> -->
                <!-- </div> -->
            <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

export default {
  props: {
    obras: Array,
    translations: Object,
    bookResult: Object
  },
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    this.swiper = new Swiper('.swiper-container', {
      spaceBetween: 41,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 6,
        },
      },
      watchOverflow: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    });
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  },
    handleImageError(event) {
        event.target.src = '/img/noImage.png'; // URL de la imagen alternativa
    }
};
</script>

<style scoped>
.swiper-container {
  width: 100%;
  height: 400px; /* Ajusta la altura según tus necesidades */
}

.book-slider-portada img {
  width: 156px;
  height: 223px;
  object-fit:cover;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
}

.book-slider-title a {
  font-size: 16px;
  font-family: 'Outfit-Regular';
  text-decoration: none;
  color: black;
}
.book-slider-price {
  font-size: 16px;
  font-family: 'Outfit-Bold';
}

.obra-item {
  transition: all 0.3s ease;
}

.obra-item:hover{
  opacity: 0.8;
}

.obra-item .buscados-div-button {
  width: 160px;
}

.obra-item .buscados-div-button button {
    font-size: 12px;
}

.swiper-pagination {
  margin-top: 10px;
  text-align: center;
}


</style>
