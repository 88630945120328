<template>
    <div >
        <div class="header-list p-4 d-flex align-items-center justify-content-start">
            <img class="buscados-shopping-cart mr-2" src="/img/icons/shopping-cart-yellow.png" >
                <span class="mi-carrito"> {{translations.miCarrito}} </span>  <span class="unidades-header">{{totalEjemplares}} {{translations.unidades}}</span>
            <div class="close-modal t-0" @click="closeModal()" >  
                <img class="key-icon" src="/img/icons/x.png" >
            </div>  
        </div>
        <div class="listado-productos p-4">
            <div v-if="totalEjemplares === 0" class="no-product">No hay ningún producto en el carrito.</div>
            
            <div v-for="obra in obras" :key="obra.obrcod" class="producto row mb-2">
                <div class="col-3 portada">
                    <a :href="'/ficha/'+obra.obrcod"> 
                        <img class="portada-img w-100"  fluid v-if="obra && obra.url_portada" @error="handleImageError" :src="obra.url_portada">
                        <img class="portada-img w-100"  fluid v-else src="/img/noImage.png">
                    </a>                    
                </div>
                <div class="col-9">
                    <div class="titulo">
                       <a :href="'/ficha/'+obra.obrcod"> 
                            {{obra.obrtit}}
                        </a>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="">
                            <div class="unidades">
                                {{obra.ejemplares}} {{translations.unidades}}
                            </div>
                            <div class="precio">
                            {{ obra.precio_euros.replace('.', ',') }} €
                            </div> 
                            <div class="tipo">
                                <div class="d-flex dCarritoDigital" v-if="obra.tipo=='D' && domain=='portal'">
                                    <span class="txtCarritoDigital">Digital</span>
                                </div>
                                <div class="d-flex dCarritoPapel" v-if="obra.tipo=='P' && domain=='portal'">
                                    <span class="txtCarritoPapel">Papel</span>
                                </div>
                            </div>                                       
                        </div>  
                        <div class="d-flex align-items-start justify-content-start col-3 buscador-contador">
							<input-spinner-resumen
								:obra="obra"
							></input-spinner-resumen>
		                </div>
                        <a class="delete-product"  @click="deleteObra(obra.obrcod, $event.target)">
                            <img class="w-75 shopping-cart trash-icon" src="/img/icons/trash.svg" >
                        </a>
                    </div>
                </div>
            </div>           
        </div>
        <div class="subtotal px-2 px-sm-4  py-3 ">
            <div class="d-flex align-items-center justify-content-start ">
                <div class="flex-grow-1"><span class="subtotal-text">{{translations.subtotal}}: </span> <span class="cantidad ps-1">{{parseFloat(subtotal).toFixed(2).replace('.', ',')}} €</span></div>
                <div><button @click="goToResumenCarrito()" class="fin-compra ml-auto btn btn-warning bg-anaya w-100 d-flex align-items-center justify-content-center">{{translations.finalizar}} {{translations.compra}} <img class="right-arrow" src="/img/icons/arrow-right.svg" ></button></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            cartProp: Object,
            translations: Object,
            domain: null
        },
        data(){
            return{
                obras: [],
                subtotal: 0,
                totalEjemplares:0
            }
        },                  
        mounted() {
            this.actualizarCarrito();
            EventBus.$on('actualizarCarrito', carrito => {
                this.obras = Object.entries(carrito).map((arr) => ( arr[1]));
                this.totalEjemplares = 0;
                this.obras.forEach(obra => {
                    this.totalEjemplares += obra.ejemplares;
                });
                // Calculamos el subtotal
                this.subtotal = 0
                this.obras.forEach(element => {
                    this.subtotal = this.subtotal + (parseInt(element.ejemplares) * parseFloat(element.precio_euros).toFixed(2));
                });
            });
        },
        methods: {
            closeModal(){
                var carritoHeader = document.querySelector('.carrito-header')
                carritoHeader.classList.toggle('active')
            },
            actualizarCarrito(){
                axios.get('/verCarrito',{
                    headers: {
                        //token: getAcessToken()
                    }
                })
                .then(response => {

                    this.obras = Object.entries(response.data).map((arr) => ( arr[1]));
                    
                    this.totalEjemplares = 0;
                    this.obras.forEach(obra => {
                        this.totalEjemplares += obra.ejemplares;
                    });
                    // Calculamos el subtotal
                    this.subtotal = 0
                    this.obras.forEach(element => {
                        this.subtotal = this.subtotal + (parseInt(element.ejemplares) * parseFloat(element.precio_euros).toFixed(2));
                    });
                    document.querySelectorAll('.carrito-selector .listado-productos')[0].classList.remove('mm-disabled')
                })
                .catch(e => {
                    //TODO: Redirigir error
                    document.querySelectorAll('.carrito-selector .listado-productos')[0].classList.remove('mm-disabled')
                });
            },
            deleteObra(obrcod, element) {
                const url = `/borrarObra?obrcod=${obrcod}`;
                document.querySelectorAll('.carrito-selector .listado-productos')[0].classList.add('mm-disabled')
                axios.get(url)
                .then(response => {
                    if(this.obras.length = 1){
                        document.getElementById('punto-carrito').classList.add('d-none');
                    }
                    this.actualizarCarrito();
                    if (window.location.pathname === '/resumen-carrito') {
                        window.location.reload();
                    }
                })
                .catch(error => {
                    document.querySelectorAll('.carrito-selector .listado-productos')[0].classList.remove('mm-disabled')
                    console.error('Error al borrar la obra:', error);
                });
            },
            goToResumenCarrito(){
                window.location = "/resumen-carrito";
            },
            handleImageError(event) {
               event.target.src = '/img/noImage.png'; // URL de la imagen alternativa
            }
        },        
    }
</script>
