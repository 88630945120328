<template>
  <div id="comunidadModal"  class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
          <div class="modal-content m-auto login-modal">
              <div class="modal-body">
                  <form id="formComunidad" method="POST" action="/elegirComunidad">
                      <div class="modal-login-header mb-2">
                          {{ translations.eligeComunidadTitulo }}
                      </div>
                      <div class="modal-comunidad-input-header__subtitle mb-4 text-center">
                        {{ translations.eligeComunidadSubtitulo }}
                      </div>
                      <div class="mb-4">
                        <div class="col-12">
                          <div class="modal-comunidad-input-header mb-2">
                            {{ translations.pais }}
                          </div>
                          <div>
                            <country-select :placeholder="translations.introducePais"></country-select>
                          </div>
                        </div>
                          <input type="hidden" name="filtroCCAAUsuario" id="filtroCCAAUsuario">
                          <input type="hidden" name="_token" :value="csrf">
                          <div id="autonomiaSelectorContainer" v-if="autonomias" class="div-parent-multiselect">
                              <div class="modal-comunidad-input-header mb-2">
                                {{ translations.comunidadAutonoma }}
                              </div>   
                              <multiselect
                                id="autonomiaSelector"
                                class="select-buscador"
                                v-model="selectedComunidad"
                                :options="autonomias"
                                :multiple="false"
                                label="autonomia"
                                track-by="autonomia"
                                :placeholder="translations.eligeComunidadPlaceholder"
                                :show-labels="false"
                                no-options="Sin resultados"
                                no-result="Sin resultados"
                                :taggable="false"     
                                :allow-empty="false"
                                @input="(value) => handleInputSearchForm( value.autonomia, 'filtroCCAAUsuario')"    
                              >
                                <span slot="noResult">{{translations.sinOpcionesBusqueda}}</span>
                              </multiselect>
                          </div>    
                          <div id="error-comunidad" class="text-error-comunidad mb-2 d-none">{{translations.seleccionaComunidadError}}</div>                  
                      </div>
                   

                      <div class="modal-login-acceder-div mb-4">
                          <button @click="submit" id="submitComunidad" type="button" class="btn btn-warning bg-anaya button-login-text p-2">
                              {{ translations.elegir }}
                          </button>
                      </div>
                  </form>                                                      
              </div>
          </div>
      </div>
  </div>
</template>

<script>

    import Multiselect from 'vue-multiselect'
    import {
      BCol, BFormGroup, BFormInput, BRow,
    } from 'bootstrap-vue'    
    export default {
        components: {
        BFormInput,
        BFormGroup,
        BRow,
        BCol,            
          Multiselect,
        },        
        props: {
            translations: Object,
            csrf: String, // Define la propiedad 'translations' como un objeto
        },   
        data() {
            return {
                selectedComunidad:null,
                autonomias:[]
             }
        },     
        mounted() {
          this.getComunidades();
        },
        methods : {
            submit(){
              if(document.getElementById('filtroCCAAUsuario').value===""){
                document.getElementById('error-comunidad').classList.remove('d-none')
                return
              }else{
                let submitComunidad = document.getElementById('submitComunidad');
                submitComunidad.innerHTML = '<span class="loader"></span>';
                submitComunidad.classList.add('mm-disabled');
                document.getElementById('formComunidad').submit()
              }
            },
            getComunidades(){
                let dataAutonomias = localStorage.getItem('dataAutonomias');
                if(dataAutonomias != null && typeof dataAutonomias != undefined && dataAutonomias != 'undefined') {
                  this.autonomias = JSON.parse(dataAutonomias);
                } else {
                  axios.get('/getAutonomias',{
                      headers: {
                          //token: getAcessToken()
                      }
                  })
                  .then(response => {
                    if(response!==null){
                      this.autonomias = response.data
                      localStorage.setItem('dataAutonomias', JSON.stringify(response.data));
                    }
                        
                  })
                  .catch(e => {
                      //TODO: Redirigir error
                  });
                }
            },
            handleInputSearchForm(value, nameInput) {  
              document.getElementById('error-comunidad').classList.add('d-none') 
              document.getElementById(nameInput).value=value;   
            },
        }
    }
</script>
<style lang="scss">
@import '/node_modules/vue-multiselect/dist/vue-multiselect.min.css';
</style>
