<template>
    <div >
    </div>
</template>

<script>
import toastr from 'toastr'
    export default {
        props: {},
        data(){
            return{
            }
        },
        mounted() {
            EventBus.$on('toastNotification', notification => {
                switch (notification.type) {
                    case 'success':
                        toastr.success(notification.body, notification.title, {timeOut: 5000})
                    break;
                    case 'warning':
                        toastr.warning(notification.body, notification.title, {timeOut: 5000})
                    break;
                    case 'error':
                        toastr.error(notification.body, notification.title, {timeOut: 5000})
                    break;
                    default:
                        toastr.error(notification.body, notification.title, {timeOut: 5000})
                    break;
                }
                console.log(notification);
            });
        },
        methods: {}

    }
</script>
