<template>
    <div @focusin=" " >		
    <!-- @focusout="setOpacity(1)" -->
        <form action="catalogo" method="GET" class="position-relative" style="margin: 0 auto;">
            <input type="hidden" name="type" value="rapida">
            <input 
              type="text" 
              name="stringBusqueda" 
              @value="inputValue" 
              required 
              v-model="searchTerm" 
              @input="onInput" 
              placeholder="Busca por título, autor o ISBN..." 
              class="form-control input-ppal mb-3"
              @focusin="onFocusInput"
              @focusout="onFocusOutInput"
              >
            <div v-if="loading" class="spinner">
              <span class="loader opensearch-loader"></span>
            </div>
            <div v-else class="mx-auto" style="">
              <div v-if="results.length > 0" id="opensearch-results-container" class="opensearch-results-container">
                   <div v-for="(chunk, index) in chunkedResults" :key="index" :class="`opensearch-results-row-${index} d-flex flex-wrap ${chunk.length === 5 ? 'justify-content-between' : 'justify-content-start'}`">
                      <div v-for="result in chunk" :key="result._id" class="col-12 col-lg-6 col-xl-6 libro p-4 py-2">
                        <a :href="'/ficha/' + result._source.doc.obrcod" class="text-decoration-none">
                          <div class="card shadow-none p-0 m-2">
                            <img class="buscador-portada-img" v-if="result._source.doc.url_portada_grande" @error="handleImageError" :src="result._source.doc.url_portada_grande">
                            <img class="buscador-portada-img" v-else src="/img/noImage.png">
                            <div>
                              <h6 class="font-bold text-lg mt-3 text-decoration-none"v-html="getHighlightedTitle(result)"></h6>
                              <p class="text-decoration-none buscados-card-editorial mb-2">{{ result._source.doc.editorial }}</p>
                              <p class="text-decoration-none buscados-card-editorial mb-2" v-html="getHighlightedISBN(result)"></p>
                              <p class="text-decoration-none buscados-card-editorial mb-2 data-opensearch-autores" v-html="getHighlightedAuthors(result)"></p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  <div class="div-button d-flex justify-content-center mt-3">
                      <button type="submit" class="btn btn-warning btn-block btn-lg buscar-avanzada">Ver todos los resultados</button>
                  </div>
              </div>
              <div v-else-if="searchTerm.length > 0 && results == -1">
              </div>
              <div v-else-if="searchTerm.length > 0 && results.length == 0">
                  <p>No se encontraron resultados.</p>
              </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash/debounce';

export default {
  props: {
    comunidad: String
  },
  data() {
    return {
        translations: Object,
        autonomia: null,
        domain: String,
        searchTerm: '',
        inputValue: window.initialStringBusqueda || '',
        results: [],
        loading: false,
        debouncedSearch: null,
    };
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  computed: {
    chunkedResults() {
      const chunkSize = 6;
      const chunks = [];
      for (let i = 0; i < this.results.length; i += chunkSize) {
        chunks.push(this.results.slice(i, i + chunkSize));
      }
      return chunks;
    }
  },
    methods: {
      getHighlightedTitle(result) {
        if (result.highlight && result.highlight['doc.obrtit']) {
          return result.highlight['doc.obrtit'].join(' ');
        } else {
          return result._source.doc.obrtit;
        }
      },
      getHighlightedISBN(result) {
        if (result.highlight && result.highlight['doc.obrisb']) {
          return result.highlight['doc.obrisb'].join(' ');
        } else {
          return result._source.doc.obrisb;
        }
      },
      getHighlightedAuthors(result) {
        if (result.highlight && result.highlight['doc.autores_lista_precios']) {
          return result.highlight['doc.autores_lista_precios'].join(' ');
        } else {
          return result._source.doc.autores_lista_precios;
        }
      },

      isValidISBN(term) {
        const cleanedTerm = term.replace(/[^0-9]/g, '');

        return cleanedTerm.length === 13 || cleanedTerm.length === 10;
      },

      formatISBN(term) {
        const cleanedTerm = term.replace(/[^0-9]/g, '');

        if (cleanedTerm.length === 13) {
          return cleanedTerm.replace(/(\d{3})(\d{2})(\d{3})(\d{4})(\d{1})/, '$1-$2-$3-$4-$5');
        } else if (cleanedTerm.length === 10) {
          return cleanedTerm.replace(/(\d{1})(\d{3})(\d{5})(\d{1})/, '$1-$2-$3-$4');
        }
        return term; // Retorna el término original si no coincide con el formato de ISBN
      },
      removeAccents(str) {
        return str.replace(/á/g, 'a')
                  .replace(/é/g, 'e')
                  .replace(/í/g, 'i')
                  .replace(/ó/g, 'o')
                  .replace(/ú/g, 'u')
                  .replace(/Á/g, 'A')
                  .replace(/É/g, 'E')
                  .replace(/Í/g, 'I')
                  .replace(/Ó/g, 'O')
                  .replace(/Ú/g, 'U');
      },

      async handleSearch() {
        let appDivs = document.querySelectorAll('#app > div');
        if(document.querySelector('.home')){
          appDivs = document.querySelectorAll('#app .home > div');
        }
        if(this.results.length == 0 || this.results == undefined) {
          if(this.searchTerm.length != 0) {
            appDivs.forEach(div => {
              div.style.opacity = '0.3';
            });
          }
        }else if(this.searchTerm.length != 0){
          appDivs.forEach(div => {
              div.style.opacity = '0.3';
            });
        }
        const buscadorStatic = document.getElementById('buscador-static')
        const buscadorGeneral = document.getElementById('buscador-general')

        if(buscadorStatic) {
          buscadorStatic.style.opacity = '1'
        }
        if(buscadorGeneral) {
          buscadorGeneral.style.opacity = '1'
        }

        this.domain = window.domain
        const tipo = (this.domain == 'tienda') ? ["D"] : ["D", "P"]
        const excludeMaterialCod = (this.domain == 'tienda') ? ["Lectura", "Cuaderno de Vacaciones"] : []

        if (this.searchTerm.length === 0) {
          this.results = [];
          this.loading = false;
          return;
        }

        try {
          delete axios.defaults.headers.common['X-Requested-With'];

          // Construir la consulta en función del formato de búsqueda
          const searchTermWithoutAccents = this.removeAccents(this.searchTerm);
          const searchTermLowerCase = searchTermWithoutAccents.toLowerCase();

          const query = {
            "query": {
              "bool": {
                "must": [
                  {
                    "bool": {
                      "should": [
                        {
                          "match": {
                            "doc.obrtit": {
                              "query": searchTermLowerCase,  // Cambia a minúsculas
                              "fuzziness": "auto",
                              "boost": 5
                            }
                          }
                        },
                        {
                          "prefix": {
                            "doc.obrtit": {
                              "value": searchTermLowerCase,
                              "boost": 5
                            }
                          }
                        },
                        {
                          "match": {
                            "doc.autores_lista_precios": {
                              "query": searchTermLowerCase,
                              "fuzziness": "auto",
                              "boost": 1
                            }
                          }
                        },
                        {
                          "prefix": {
                            "doc.autores_lista_precios": {
                              "value": searchTermLowerCase,
                              "boost": 1
                            }
                          }
                        },
                        {
                          "match": {
                            "doc.obrisb": {
                              "query": searchTermLowerCase,
                              "boost": 3
                            }
                          }
                        },
                        {
                          "prefix": {
                            "doc.obrisb": {
                              "value": searchTermLowerCase,
                              "boost": 3
                            }
                          }
                        }
                      ]
                    }
                  },
                  {
                    "term": {
                      "doc.autonomia_cod.keyword": this.comunidad
                    }
                  }
                ],
                "filter": [
                  {
                    "terms": {
                      "doc.tipo.keyword": tipo
                    }
                  }
                ],
                "must_not": [
                  {
                    "terms": {
                        "doc.material_cod.keyword": excludeMaterialCod
                    }
                  }
                ]
              }
            },
            "highlight": {
              "fields": {
                "doc.obrtit": {
                  "fragment_size": 50,
                  "no_match_size": 0,
                  "pre_tags": ["<em>"],
                  "post_tags": ["</em>"]
                },
                "doc.autores_lista_precios": {
                  "fragment_size": 50,
                  "no_match_size": 0,
                  "pre_tags": ["<em>"],
                  "post_tags": ["</em>"]
                },
                "doc.obrisb": {
                  "type": "plain",  // Utiliza el tipo de highlight 'plain'
                  "number_of_fragments": 0,  // No fragmentar, resaltar el texto completo
                  "pre_tags": ["<em>"],
                  "post_tags": ["</em>"]
                }
              }
            },
            "size": 6
          };


          // Realizar la búsqueda con Axios
          const response = await axios.post(
            'https://xfnpzv3k35.execute-api.eu-west-1.amazonaws.com/prod/search/',
            query
          );

          
          this.results = response.data.body.hits.hits;
          
        } catch (error) {
          console.error('Error al realizar la búsqueda:', error);
          this.results = [];
        } finally {
          if(this.searchTerm.length === 0){
            this.results = -1;
          }
          this.loading = false;
        }
      },

        handleImageError(event) {
            event.target.src = '/img/noImage.png'; // URL de la imagen alternativa
        },
        debounce(func, wait) {
          let timeout;
          return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
          };
        },
        onInput() {
          this.loading = true; // Mostrar spinner inmediatamente
          this.debouncedSearch(); // Llamar a la búsqueda con debounce
        },
        onFocusInput(){
          if(this.results.length > 0) {
            return
          }
          this.onInput()
        },
        onFocusOutInput(){
          if(this.results.length == 0 || this.results == undefined) {
            if(this.searchTerm.length == 0) {
              this.setOpacity(1)
            }
          }
        },
        handleOutsideClick(event) {
          const buscadorGeneral = document.getElementById('buscador-general');
          const buscadorStatic = document.getElementById('buscador-static')

          if(buscadorStatic) {
            buscadorStatic.style.opacity = '1'
          }
          if(buscadorGeneral) {
            buscadorGeneral.style.opacity = '1'
          }
          
          if (buscadorGeneral && !buscadorGeneral.contains(event.target)) {
            this.setOpacity(1); // Llamar a la función setOpacity con valor 1
            
          }

        },

        // Función para cambiar la opacidad de los divs en '#app'
        setOpacity(value) {
          let appDivs = document.querySelectorAll('#app > div');
          if(document.querySelector('.home')){
            appDivs = document.querySelectorAll('#app .home > div');
          }
          
          this.results = -1;
          
          appDivs.forEach(div => {
            div.style.opacity = value;
          });
          
          const buscadorStatic = document.getElementById('buscador-static')
          const buscadorGeneral = document.getElementById('buscador-general')

          if(buscadorStatic) {
            buscadorStatic.style.opacity = '1'
          }
          if(buscadorGeneral) {
            buscadorGeneral.style.opacity = '1'
          }
        }

        

    },
    created() {
      this.debouncedSearch = debounce(this.handleSearch, 100);
    }
    
};
</script>